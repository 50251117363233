.wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: calc(100% - 120px);
  padding: 0 60px;
  margin: 0 auto;
}

.title {
  font-size: 50px;
  padding: 20px 0 50px;
}

.text {
  font-family: "Acumin Pro", sans-serif;
  line-height: 23px;
  margin: 0;

  & br:first-of-type {
    display: none;
  }

  & p:last-of-type{
    margin: 16px 0 0 0;
  }
  

  a {
    color: #000000;
    font-weight: 600;
    transition: 0.3s all ease-in-out;

    &:hover{
      color: #bf323b;
    }
  }
}

a {
  color: #000000;
  transition: 0.3s all ease-in-out;

  &:hover{
    color: #bf323b;
  }
}


