.inputWrapper {
  display: flex;
  flex-flow: row;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 209px;
  background-color: #515151;
  border: 2px solid #ffffff;
  font-family: "Acumin Pro", sans-serif;
  margin-right: 20px;
  cursor: pointer;
  transition: all 0.3s;
  padding: 20px 20px;
  user-select: none;
  opacity: 1;
  visibility: visible;
  height: 17px;

  .arrow {
    background-size: cover;
    width: 17px;
    height: 17px;
    background-color: #ffffff;
    -webkit-mask-image: url("../../../src/assets/image/search/Arrow.svg");
    mask-image: url("../../../src/assets/image/search/Arrow.svg");
    transition: 0.5s all;
    flex-shrink: 0;
  }

  .titlesWrapper {
    display: flex;
    flex-flow: column;
    max-width: 93%;
    align-items: center;
    width: 100%;

    .title {
      font-size: 22px;
      color: #ffffff;
      transition: 0.3s all;
      display: flex;
      align-items: flex-end;
      margin-top: 4px;
    }
  }
}

.inputWrapperOpen {
  background-color: #e7e7e7;
  border: 2px solid #e7e7e7;
  padding: 20px 20px;

  .titlesWrapper{

    .title {
      color: #202020;
    }
  }

  .arrow {
    transform: rotate(180deg);
    background-color: #202020;
  }
}

.inputWrapperSeted {
  background-color: #e7e7e7;
  border: 2px solid #e7e7e7;
  padding: 20px 20px;

  .titlesWrapper{
    .title {
      color: #202020;
    }
  }

  .arrow {
    background-color: #202020;
  }
}

.inputWrapperHidden {
  opacity: 0;
  visibility: hidden;
}


.modalWrapper {
  margin: 0 auto;
  overflow: hidden;
  position: absolute;
  left: -2px;
  top: calc(100% + 2px);
  right: 0;
  border: 1px solid #c2c2c2;
  display: none;
  background-color: white;
  border-top: none;
  border-radius: 0;
  width: calc(100% + 2px);
  max-height: 34vh;
  z-index: 1000;
  transition: 0.4s all ease-in-out;
  flex-flow: column;
}

.modalWrapperOpen {
  display: flex;
  overflow: auto
}

.modalWrapperOpenNoScroll {
  display: flex;
}

.modalWrapper::-webkit-scrollbar-thumb {
  background: #a2a2a2;
  -webkit-border-radius: 12px;
  border: 4px #ebecec solid;
  border-right: 3px #ebecec solid;
}

.modalWrapper::-webkit-scrollbar-corner {
  background: #ebecec;
}

.modalWrapper::-webkit-scrollbar {
  background: #ebecec;
  width: 14px;
}

.item {
  padding: 5px 10px 9px 15px;
  background-color: white;
  cursor: pointer;
  transition: 0.3s ease;
  font-size: 22px;
  color: #000000;
}

.item:first-child {
  padding-top: 10px;
}

.item:last-child {
  padding-bottom: 14px;
}

.item:hover{
  color: #bf313c;
}

@media screen and (max-width: 1100px) {
    .inputWrapper {
      max-width: 182px;
      padding: 15px 15px;

      .titlesWrapper {

        .title {
          font-size: 18px;
        }
      }
    }
}