.inputsWrapper {
  display: none;
  grid-template-columns: 1fr 1fr 1fr 1fr ;
  width: 70%;
  margin-top: 20px;
  max-width: 1345px;

  .inputWrapper {
    display: none;
    grid-template-columns:65px auto;
    align-items: center;
    margin-top: 14px;
    opacity: 0;

    .check {
      width: 35px;
      height: 35px;
      flex-shrink: 0;
      margin-right: 34px;
      box-shadow: 0 0 1px #ffffff;
    }

    .check input {
      display: none;
    }

    .boxChecked {
      width: 100%;
      height: 100%;
      transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
      border: 2px solid #ffffff;
      background-color: #515151;
      position: relative;
      overflow: hidden;
      cursor: pointer;
    }

    .boxChecked:after{
      width: 50%;
      height: 20%;
      content: '';
      position: absolute;
      border-left: 7.5px solid;
      border-bottom: 7.5px solid;
      border-color: #ffffff;
      transform: rotate(-45deg) translate3d(0, 0, 0);
      transform-origin: center center;
      transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
      left: 0;
      right: 0;
      top: 0;
      bottom: 5%;
      margin: auto;
    }

    .boxUnChecked {
      width: 100%;
      height: 100%;
      transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
      border: 2px solid #ffffff;
      background-color: #515151;
      position: relative;
      overflow: hidden;
      cursor: pointer;
    }

    .boxUnChecked:after {
      width: 50%;
      height: 20%;
      content: '';
      position: absolute;
      border-left: 7.5px solid;
      border-bottom: 7.5px solid;
      border-color: #ffffff;
      transform: rotate(-45deg) translate3d(0, 0, 0);
      transform-origin: center center;
      transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
      left: 0;
      right: 0;
      top: 200%;
      bottom: 5%;
      margin: auto;
    }

    .checkboxText {
      margin-top: 5px;
      font-size: 24px;
      color: #ffffff;
      font-family: "Acumin Pro", sans-serif;
    }
  }
}

.buttonsWrapper {
  display: none;
  width: 70%;
  justify-content: flex-end;
  margin: 30px 0;
  max-width: 1345px;

  .clearButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    height: 70px;
    background-color: #515151;
    color: #ffffff;
    border: 3px solid #ffffff;
    font-size: 28px;
    font-family: "Acumin Pro", sans-serif;
    margin-right: 50px;
    cursor: pointer;
    transition: all 0.3s;
  }

  /*.clearButton:hover {
    background-color: #bf323b;
  }*/

  .searchButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    height: 70px;
    background-color: #bf323b;
    color: #ffffff;
    border: 3px solid #ffffff;
    font-size: 28px;
    font-family: "Acumin Pro", sans-serif;
    cursor: pointer;
    transition: all 0.3s;
  }

  /*.searchButton:hover {
    background-color: #515151;
  }*/
}

@media screen and (max-width: 1700px) {
  .inputsWrapper {
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: 100px;
  }
}

@media screen and (max-width: 1400px) {
  .inputsWrapper {
    width: 80%;
  }

  .buttonsWrapper {
    width: 80%;
  }
}

@media screen and (max-width: 1100px) {
  .inputsWrapper {
    width: 80%;
    margin-left: 50px;
    grid-column-gap: 10px;

    .inputWrapper {
      grid-template-columns:49px auto;

      .check {
        width: 25px;
        height: 25px;
        flex-shrink: 0;
        margin-right: 24px;
      }

      .boxChecked:after{
        border-color: #ffffff !important;
        border-left: 5.25px solid;
        border-bottom: 5.25px solid;
      }

      .boxUnChecked:after {
        border-color: #ffffff !important;
        border-left: 5.25px solid;
        border-bottom: 5.25px solid;
      }

      .checkboxText {
        font-size: 17px;
      }
    }
  }

  .buttonsWrapper {

    .clearButton,.searchButton {
      width: 182px;
      height: 49px;
      border: 2px solid #ffffff;
      font-size: 19px;
    }
  }
}

@media screen and (max-width: 800px) {
  .inputsWrapper {
    width: 90%;
  }
}

@media screen and (max-width: 740px) {
  .inputsWrapper {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
    width: auto;
    margin-left: unset;
  }
}

@media screen and (max-width: 540px) {
  .inputsWrapper {
    grid-column-gap: 20px;
  }

  .buttonsWrapper {
    justify-content: space-between;
  }
}

@media screen and (max-width: 470px) {
  .inputsWrapper {
    grid-template-columns: auto;
    overflow-y: scroll;
    width: calc(100% - 60px);
    max-width: 320px;
    margin: 20px auto 0 auto;
  }

  .buttonsWrapper {
    margin-bottom: 70px;
  }
}









