.listWrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: calc(100% - 120px);
  padding: 0 60px;
  margin: 0 auto;

  .scrollTopButton {
    position: fixed;
    bottom: 80px;
    right: 30px;
    background-image: url(../../assets/image/list/arrow-up.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30px auto;
    background-color: #ffffff;
    width: 30px;
    height: 30px;
    z-index: 1000;
    padding: 10px;
    border-radius: 5px;
    border: 1px #000000 solid;
    cursor: pointer;
    opacity: 0.5;
    transition: 0.3s all;
  }

  .scrollTopButton:hover {
    opacity: 1;
  }

  .top {
    display: flex;
    width: 100%;
    flex-flow: column;
    margin-top: 32px;
    margin-bottom: 50px;
    position: relative;

    .title {
      font-size: 36px;
      letter-spacing: 0;
      color: #000000;
      font-weight: bold;
      font-family: "Acumin Pro", sans-serif;
      display: flex;
      align-items: center;
      margin-bottom: 17px;
      white-space: nowrap;
      text-transform: capitalize;

      .line {
        background-color: #ffffff;
        border-bottom: 3px solid #000000;
        width: 395px;
        margin-left: 30px;
        margin-bottom: 5px;
      }
    }

    .description {
      font-size: 24px;
      letter-spacing: 0;
      color: #000000;
      font-family: "Acumin Pro", sans-serif;
    }

    .changeViewType {
      display: flex;
      position: absolute;
      background-color: #bf323b;
      padding: 20px;
      top: -113px;

      .view_type_1 {
        width: 42px;
        height: 41px;
        cursor: pointer;
        margin-right: 25px;
      }

      .view_type_2 {
        width: 47px;
        height: 41px;
        cursor: pointer;
      }

      .shadow {
        opacity: 0.5;
      }
    }
  }

  .listTiles {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    margin: 0 auto 50px auto;
    max-width: 1920px;

    .item {
      display: grid;
      align-items: flex-start;
      grid-auto-rows: calc(calc(100vw - 260px)/3) auto;
      width: calc(calc(100vw - 260px)/3);
      max-width: 560px;
      margin: 0 20px 50px 20px;
      position: relative;
      text-decoration: unset;

      .photo {
        max-width: 100%;
        max-height: calc(calc(100vw - 260px)/3);
        height: 100%;
        width: 100%;
        object-fit: contain;
      }

      .photoVideo {
        background: #515151 url(../../../src/assets/image/gallery/big-play.svg) no-repeat center;
        background-size: auto;
      }

      .photoVideoImage {
        position: absolute;
        background: url(../../../src/assets/image/gallery/big-play.svg) no-repeat center;
        background-size: auto;
        z-index: 100;
        top: 0;
      }

      .mainPhotoNan {
        height: 100%;
        display: flex;
        align-items: center;
      }

      .description {
        display: flex;
        flex-flow: row;

        .title {
          font-size: 24px;
          letter-spacing: 0;
          color: #000000;
          font-weight: bold;
          font-family: "Acumin Pro", sans-serif;
          align-self: center;
          margin: 23px 0;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
      }
    }
  }

  .listNormal {
    display: flex;
    flex-flow: column;
    width: 100%;
    margin-bottom: 115px;

    .item {
      display: grid;
      grid-template-columns: 260px auto;
      padding: 34px;
      border-bottom: 2px solid #818181;
      position: relative;

      .photo {
        width: 100%;
        height: auto;
        display: block;
      }

      .photoVideo {
        background: #515151 url(../../../src/assets/image/gallery/big-play.svg) no-repeat center;
        background-size: auto;
        height: 260px;
      }

      .photoVideoImage {
        position: absolute;
        background: url(../../../src/assets/image/gallery/big-play.svg) no-repeat center;
        background-size: 30% 30%;
        z-index: 100;
        top: 0;
        height: 260px;
      }

      .mainPhotoNan {
        flex: 1;
        position: relative;
      }

      .description {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        margin-left: 80px;

        .title {
          font-size: 30px;
          letter-spacing: 0;
          color: #000000;
          font-weight: bold;
          font-family: "Acumin Pro", sans-serif;
          padding-bottom: 20px;
        }

        .type {
          font-size: 24px;
          letter-spacing: 0;
          color: #c08164;
          font-family: "Acumin Pro", sans-serif;
        }
      }
    }
  }

  .empty {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 400px;
    justify-content: center;
    align-items: center;

    span {
      font-size: 40px;
      color: #000000;
      font-family: "Acumin Pro", sans-serif;
    }
  }
}

@media screen and (max-width: 1200px) {
  .listWrapper {

    .listTiles {

      .item {
        grid-auto-rows: calc(calc(100vw - 220px) / 2) auto;
        width: calc(calc(100vw - 220px) / 2);

        .photo {
          max-height: calc(calc(100vw - 220px) / 2)
        }
      }
    }

    .top {

      .title {
        font-size: 28px;
        margin-bottom: 13px;
      }

      .description {
        font-size: 19px;
      }

      .changeViewType {
        padding: 20px;
        top: -90px;

        .view_type_1 {
          width: 33px;
          height: 32px;
          cursor: pointer;
          margin-right: 20px;
        }

        .view_type_2 {
          width: 37px;
          height: 32px;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .listWrapper {

    .top {

      .changeViewType {
        padding: 16px;
        top: -72px;

        .view_type_1 {
          width: 26px;
          height: 25px;
          cursor: pointer;
          margin-right: 16px;
        }

        .view_type_2 {
          width: 29px;
          height: 25px;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .listWrapper {
    max-width: calc(100% - 40px);
    padding: 0 20px;

    .scrollTopButton {
      bottom: 55px;
      right: 20px;
      background-size: 20px auto;
      width: 20px;
      height: 20px;
    }

    .listTiles {

      .item {
        grid-auto-rows: calc(calc(100vw - 80px)) auto;
        max-width: calc(100vw - 80px);
        width: 100%;

        .photo {
          max-height: calc(calc(100vw - 80px))
        }
      }
    }

    .listNormal {
      margin-bottom: 50px;

      .item {
        grid-template-columns: 200px auto;
        padding: 20px;

        .photoVideo {
          height: 200px;
        }

        .photoVideoImage {
          height: 200px;
        }

        .description {
          margin-left: 40px;

          .title {
            font-size: 21px;
            padding-bottom: 14px;
          }

          .type {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .listWrapper {

    .top {

      .changeViewType {
        padding: 12px;
        top: -57px;

        .view_type_1 {
          width: 21px;
          height: 20px;
          cursor: pointer;
          margin-right: 12px;
        }

        .view_type_2 {
          width: 23px;
          height: 20px;
          cursor: pointer;
        }

      }
    }
  }
}

@media screen and (max-width: 580px) {
  .listWrapper {
    margin-top: 50px;

    .top {

      .title {
        white-space: unset;
        font-size: 22px;
        margin-bottom: 10px;

        .line {
          display: none;
        }
      }

      .description {
        font-size: 15px;
      }
    }

    .listTiles {

      .item {

        .description {

          .title {
            font-size: 19px;
          }
        }
      }
    }

    .listNormal {

      .item {
        grid-template-columns: 130px auto;
        padding: 14px;

        .photoVideo {
          height: 130px;
        }

        .photoVideoImage {
          height: 130px;
        }

        .description {
          margin-left: 28px;

          .title {
            font-size: 14px;
            padding-bottom: 10px;
          }

          .type {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1921px) {
  .listWrapper {

    .listTiles {

      .item {
        grid-auto-rows: 560px auto;
        width: 560px;

        .photo {
          max-height: 560px;
        }
      }
    }
  }
}