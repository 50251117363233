.navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.navigationGalleryAndObject {
  left: 0;
}

.menuToggle {
  display: block;
  position: relative;
  top: 50px;
  left: 48px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

.menuToggle a {
  text-decoration: none;
  color: #000000;
  transition: color 0.3s ease;
}

.menuToggle a:hover {
  color: #000000;
}

.menuToggle input {
  display: block;
  width: 60px;
  height: 47px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  -webkit-touch-callout: none;
}

.menuToggle span {
  display: block;
  width: 60px;
  height: 8px;
  margin-bottom: 9px;
  position: relative;
  background: #bf323b;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
  background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
  opacity 0.55s ease;
}

.menuToggle span:first-child {
  transform-origin: 0 0;
}

.menuToggle span:nth-last-child(3) {
  transform-origin: 0 100%;
}

.menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #ffffff;
}

.menuToggle input:checked ~ span:nth-last-child(4) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

.menuToggle input:checked ~ span:nth-last-child(3) {
  transform: rotate(-45deg) translate(0, -1px);
}

.menu {
  position: absolute;
  width: 250px;
  height: 100vh;
  margin: -101px 0 0 -50px;
  padding: 125px 50px 50px 50px;
  background: #bf323b;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0 0;
  transform: translate(-101%, 0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

  .nifc {
    padding-top: 70px;
  }

  img {
    margin-top: 10px;
  }
}

.menuHider {
  position: absolute;
  width: 270px;
  height: 100vh;
  margin: -101px 0 0 -50px;
  padding: 125px 50px 50px 50px;
  background: #ffffff;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0 0;
  transform: translate(-100%, 0);
}

.menu li {
  padding: 14px 0;
  font-size: 20px;
  color: #000000;
  font-family: "Acumin Pro", sans-serif;
  font-weight: 600;
}

.menu li .langButton {
  padding: 14px 0;
  font-size: 20px;
  color: #000000;
  font-family: "Acumin Pro", sans-serif;
  font-weight: 600;
  cursor: pointer;
}

.menuToggle input:checked ~ ul {
  transform: none;
}

.menu li:checked  ~ ul{
  transform: none;
}

@media screen and (max-width: 800px) {
  .menuToggle {
    top: 30px;
    left: 30px;
  }

  .menuToggle input {
    width: 36px;
    height: 28px;
  }

  .menuToggle span {
    width: 36px;
    height: 5px;
    margin-bottom: 5px;
  }

  .menu {
    max-width: 200px;
  }

  .menu li {
    padding: 11px 0;
    font-size: 16px;
    color: #000000;
    font-family: "Acumin Pro", sans-serif;
    font-weight: 600;
  }
}

@media screen and (max-width: 400px) {
  .menuToggle {
    top: 20px;
    left: 20px;
  }
}

@media screen and (min-width: 2560px) {
  .navigation {
    left: calc((100vw - 2560px)/2);
  }

  .navigationGalleryAndObject {
    left: 0!important;
  }
}
