@import '../../assets/styles/index';

.wrapper{
  background-size: 100% 200px;
  z-index: 200;
  display: flex;
  justify-content: center;
  max-width: 2560px;
  margin: 0 auto;
}

.headerWrapped {
  background: url('../../../src/assets/image/header/header_wrapper.jpg');
  background-repeat: no-repeat;
  background-position: 50% 285%;
  background-size: 1017px 413px;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  position: relative;
  width: 1200px;
  height: 250px;
  margin-top: -270px;
  padding-top: 257px;
  border-radius: 50% / 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  .mainTitle {
    font-size: 50px;
    color: #ffffff;
    font-family: "Anglecia", sans-serif;
    margin: 0;
    max-width: 900px;
    text-align: center;
  }

  .title {
    font-size: 42px;
    color: #ffffff;
    margin: 0 0 20px 0;
    font-family: "Avenir Book", sans-serif;
  }
}

.header {
  background: url('../../../src/assets/image/header/header_background.jpg');
  background-repeat: no-repeat;
  background-position: right;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  position: relative;
  z-index: 200;
  border-radius: unset;
  width: 100%;
  margin-top: unset;
  padding-top: unset;

  .mainTitle {
    font-size: 131px;
    color: #ffffff;
    font-family: "Anglecia", sans-serif;
    margin: 0;
    max-width: 900px;
    text-align: center;
  }

  .title {
    font-size: 66px;
    color: #ffffff;
    margin: 0;
    font-family: "Avenir Book", sans-serif;
  }
}

.ueLogo {
  position: fixed;
  background: #ffffff url(../../../src/assets/image/header/UE_LOGO.jpg) no-repeat center;
  background-size: 100% auto;
  width: 74px;
  height: 49px;
  z-index: 1000;
  right: 8px;
  bottom: 8px;
}

@media screen and (max-width: 1800px){
  .headerWrapped {

    .mainTitle {
      font-size: 59px;
    }

    .title {
      font-size: 29px;
    }
  }

  .header {
    background-size: cover;
    height: 540px;

    .mainTitle {
      font-size: 117px;
    }

    .title {
      font-size: 59px;
    }
  }
}

@media screen and (max-width: 1700px){
  .header {
    height: 486px;

    .mainTitle {
      font-size: 105px;
    }

    .title {
      font-size: 53px;
    }
  }
}

@media screen and (max-width: 1600px){
  .header {
    height: 437px;

    .mainTitle {
      font-size: 94px;
    }

    .title {
      font-size: 47px;
    }
  }
}

@media screen and (max-width: 1400px){
  .header {
    height: 393px;

    .mainTitle {
      font-size: 84px;
    }

    .title {
      font-size: 42px;
    }
  }
}

@media screen and (max-width: 1300px){
  .header {
    height: 353px;

    .mainTitle {
      font-size: 75px;
    }

    .title {
      font-size: 37px;
    }
  }
}

@media screen and (max-width: 1100px){
  .headerWrapped {
    width: 800px;
    height: 250px;
    margin-top: -153px;
    padding-top: 78px;
    background-size: 711px 289px;
    background-position: 50% 390%;

    .mainTitle {
      margin-top: 80px;
      font-size: 35px;
    }

    .title {
      font-size: 29px;
    }
  }

  .header {
    width: 100%;
    height: 317px;
    background-size: cover;
    margin-top: unset;
    padding-top: unset;
    background-position: right;

    .mainTitle {
      font-size: 67px;
      max-width: 500px;
      margin-top: unset;
    }

    .title {
      font-size: 33px;
    }
  }
}

@media screen and (max-width: 1000px){
  .header {
    height: 253px;
    background-size: cover;

    .mainTitle {
      font-size: 53px;
    }

    .title {
      font-size: 26px;
    }
  }
}

@media screen and (max-width: 800px){
  .headerWrapped {
    width: 640px;
    height: 200px;
    margin-top: -122px;
    padding-top: 62px;
    background-size: 568px 231px;
    background-position: 50% 395%;

    .mainTitle {
      margin-top: 64px;
      font-size: 28px;
    }

    .title {
      font-size: 23px;
    }
  }

  .header {
    width: 100%;
    height: 202px;
    background-size: cover;
    margin-top: unset;
    padding-top: unset;
    background-position: right;

    .mainTitle {
      font-size: 42px;
      margin-top: unset;
    }

    .title {
      font-size: 20px;
    }
  }

  .ueLogo {
    bottom: 4px;
    right: 4px;
    width: 57px;
    height: 37px;
  }
}

@media screen and (max-width: 600px){
  .headerWrapped {
    width: 100%;
    height: 161px;
    background-size: cover;
    margin-top: unset;
    padding-top: unset;
    background-position: top;
    border-radius: 0;
    transition: unset;

    .mainTitle {
      max-width: 400px;
      margin-top: 20px;
      font-size: 33px;
    }

    .title {
      font-size: 16px;
    }
  }

  .header {
    height: 161px;
    background-size: cover;
    margin-top: unset;
    padding-top: unset;
    background-position: right;
    transition: unset;

    .mainTitle {
      max-width: 300px;
      font-size: 33px;
      margin-top: unset;
    }

    .title {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 500px){
  .header {
    background-size: auto 300px;
    background-position: left;

    .mainTitle {
      max-width: 300px;
      font-size: 33px;
    }

    .title {
      font-size: 16px;
    }
  }
}
