@import '../../assets/styles/index';

.wrapper{
  background-size: 100% 200px;
  z-index: 200;
  display: flex;
  justify-content: center;
  max-width: 250px;
  max-height: 80px;
  margin: 0 auto;
}

.headerWrapped {
  background: url(../../../src/assets/image/header/header_wrapper.jpg) no-repeat 50% 285%;
  background-size: 1017px 413px;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  position: relative;
  width: 1200px;
  height: 250px;
  margin-top: -270px;
  padding-top: 257px;
  border-radius: 50% / 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  .mainTitle {
    font-size: 50px;
    color: #ffffff;
    font-family: "Anglecia", sans-serif;
    max-width: 900px;
    text-align: center;
    margin: 0 20px 0 0;
  }

  .title {
    font-size: 42px;
    color: #ffffff;
    font-family: "Avenir Book", sans-serif;
    margin: 0 20px 20px 0;
  }
}

.header {
  background-size: auto 80px;
  background: url(../../../src/assets/image/header/header_background.jpg) no-repeat right;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  position: relative;
  z-index: 200;
  border-radius: unset;
  width: 100%;
  margin-top: unset;
  padding-top: unset;
  background-size: auto 80px;
  height: 80px;

  .mainTitle {
    font-size: 18px;
    color: #ffffff;
    font-family: "Anglecia", sans-serif;
    max-width: 900px;
    text-align: center;
    margin: 0 20px 0 0;
  }

  .title {
    font-size: 9px;
    color: #ffffff;
    font-family: "Avenir Book", sans-serif;
    margin: 0 20px 0 0;
  }
}

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.menuToggle {
  display: block;
  position: relative;
  top: 6px;
  left: 6px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

.menuToggle a {
  text-decoration: none;
  color: #000000;
  transition: color 0.3s ease;
}

.menuToggle a:hover {
  color: #000000;
}

.menuToggle input {
  display: block;
  width: 13px;
  height: 13px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  -webkit-touch-callout: none;
}

.menuToggle span {
  display: block;
  width: 12px;
  height: 2px;
  margin-bottom: 1px;
  position: relative;
  background: #bf323b;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
  background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
  opacity 0.55s ease;
}

.menuToggle span:first-child {
  transform-origin: 0 0;
}

.menuToggle span:nth-last-child(3) {
  transform-origin: 0 100%;
}

.menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(0px, 0px);
  background: #ffffff;
}

.menuToggle input:checked ~ span:nth-last-child(4) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

.menuToggle input:checked ~ span:nth-last-child(3) {
  transform: rotate(-45deg) translate(1px, 0px);
}

.menu {
  position: absolute;
  width: 66px;
  height: 54px;
  margin: -19px 0 0 -6px;
  padding: 20px 10px 10px 10px;
  background: #bf323b;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0 0;
  transform: translate(-101%, 0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

.menuHider {
  position: absolute;
  width: 103px;
  height: 54px;
  margin: -19px 0 0 -6px;
  padding: 20px 10px 10px 10px;
  background: #ffffff;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0 0;
  transform: translate(-100%, 0);
}

.menu li {
  padding: 3px 0;
  font-size: 8px;
  color: #000000;
  font-family: "Acumin Pro", sans-serif;
  font-weight: 600;
}

.menu li .langButton {
  padding: 14px 0;
  font-size: 20px;
  color: #000000;
  font-family: "Acumin Pro", sans-serif;
  font-weight: 600;
  cursor: pointer;
}

.menuToggle input:checked ~ ul {
  transform: none;
}

.menu li:checked  ~ ul{
  transform: none;
}

@media screen and (min-width: 250px) {
  .navigation {
    left: calc((100vw - 250px)/2);
  }
}

