.inputsWrapper {
  display: flex;
  flex-flow: row;
  width: 70%;
  margin-top: 50px;
}

.criterionWrapper {
  display: flex;
  flex-flow: row;
  width: 70%;
  margin-top: 25px;
}

.criterion {
  display: flex;
  flex-flow: row;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 209px;
  background-color: #515151;
  border: 2px solid #ffffff;
  height: 26px;
  font-family: "Acumin Pro", sans-serif;
  margin-right: 15px;
  cursor: pointer;
  transition: all 0.3s;
  padding: 20px 20px;
  user-select: none;
  opacity: 1;
  visibility: visible;
  align-self: flex-start;

  .titlesWrapper {
    display: flex;
    width: 100%;

    .title {
      font-size: 22px;
      color: #ffffff;
      transition: 0.3s all;
      display: flex;
      align-items: flex-end;
    }

    .plus {
      font-size: 24px;
      color: #ffffff;
      transition: 0.3s all;
      margin-right: 15px;
    }
  }
}

.criterionOpen {
  background-color: #e7e7e7;
  border: 2px solid #e7e7e7;

  .titlesWrapper {

    .title {
      color: #bf323c;
    }

    .plus {
      color: #bf323c;
    }

  }
}

.criterionSeted {
  background-color: #e7e7e7;
  border: 2px solid #e7e7e7;
}

.criterionHidden {
  opacity: 0;
  visibility: hidden;
}

.optionsWrapper {
  display: none;
  flex-flow: row;
  width: calc(70% - 4px);
  overflow: hidden;
  background-color: #515151;
  border: 2px solid transparent;
  transition: 0.3s all;
  margin: 0 auto;

  .options {
    width: 100%;
    display: flex;
    flex-flow: column;
    padding: 43px 40px 0;

    .addCriteria {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: 0.3s all;
      background-color: #bf323b;
      margin-left: auto;
      flex-shrink: 0;
      border: 3px solid #ffffff;
      color: #ffffff;
      font-size: 22px;
      padding: 10px 20px;
      font-family: "Acumin Pro", sans-serif;
      margin-bottom: 20px;
    }
  }
}

.optionsWrapperOpen {
  display: flex;
  border: 2px solid #ffffff;

  .options {
    opacity: 1;
  }
}

.buttonsWrapper {
  display: flex;
  width: 70%;
  justify-content: flex-end;
  margin: 30px 0;

  .clearButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    height: 70px;
    background-color: #515151;
    color: #ffffff;
    border: 3px solid #ffffff;
    font-size: 28px;
    font-family: "Acumin Pro", sans-serif;
    margin-right: 50px;
    cursor: pointer;
    transition: all 0.3s;
  }

  .searchButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    height: 70px;
    background-color: #bf323b;
    color: #ffffff;
    border: 3px solid #ffffff;
    font-size: 28px;
    font-family: "Acumin Pro", sans-serif;
    cursor: pointer;
    transition: all 0.3s;
  }
}

.inputWrapper {
  position: relative;

  .searchInput {
    font-size: 24px;
    letter-spacing: 0;
    line-height: 91px;
    color: #000000;
    font-family: "Acumin Pro", sans-serif;
    outline: unset;
    width: 936px;
    height: 76px;
    background-color: #ffffff;
    border: unset;
    margin-top: 50px;
    padding: 0 90px 0 26px;
    transition: all 0.3s;
  }

  .searchInputOpen {
    background-color: unset;
    border-bottom: 2px solid #ffffff;
    padding: 0 90px 0 0;
    color: #ffffff;
    transition: all 0.3s;
  }

  .searchInputOpen::placeholder {
    color: #ffffff !important;
    opacity: 1;
  }

  .searchInput::placeholder {
    color: #000000;
    opacity: 1;
  }

  .searchButton {
    background: url(../../../src/assets/image/list/search-icon.svg) no-repeat center;
    position: absolute;
    height: 45px;
    width: 48px;
    top: 65px;
    right: 20px;
    cursor: pointer;
  }

  .searchButtonOpen {
    background: url(../../../src/assets/image/list/search-icon-white.png) no-repeat center;
    background-size: 45px 45px;
  }
}

@media screen and (max-width: 1500px) {
  .inputWrapper{

    .searchInput {
      width: 520px;
      margin-top: 32px;
      height: 60px;
      padding: 0 70px 0 26px;
    }

    .searchButton {
      top: 45px;
      height: 36px;
      width: 37px;
      right: 15px;
      background-size: cover;
    }
  }
}


@media screen and (max-width: 1400px) {

  .buttonsWrapper {
    width: 80%;
  }
}

@media screen and (max-width: 1100px) {
  .buttonsWrapper {

    .clearButton,.searchButton {
      width: 182px;
      height: 49px;
      border: 2px solid #ffffff;
      font-size: 19px;
    }
  }

  .inputWrapper{

    .searchInput {
      margin-top: 20px;
      width: 416px;
    }

    .searchButton {
      top: 34px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .inputWrapper {

    .searchInput {
      margin-top: 16px;
      width: 330px;
      height: 48px;
      padding: 0 52px 0 20px;
    }

    .searchButton {
      top: 26px;
      height: 31px;
      width: 31px;
      right: 10px;
    }
  }
}

@media screen and (max-width: 800px) {
  .inputWrapper {

    .searchInput {
      width: 285px;
    }
  }
}

@media screen and (max-width: 700px) {
  .inputWrapper {

    .searchInput {
      width: 265px;
    }
  }
}

@media screen and (max-width: 640px) {
  .inputWrapper {

    .searchInput {
      width: 245px;
    }
  }
}

@media screen and (max-width: 580px) {
  .inputWrapper {

    .searchInput {
      width: 380px;
      font-size: 19px;
    }
  }
}

@media screen and (max-width: 540px) {
  .buttonsWrapper {
    justify-content: space-between;
  }
}

@media screen and (max-width: 500px) {
  .inputWrapper{

    .searchInput {
      width: 320px;
      font-size: 19px;
    }
  }
}

@media screen and (max-width: 470px) {
  .buttonsWrapper {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 400px) {
  .inputWrapper{

    .searchInput {
      width: 220px;
      font-size: 19px;
    }
  }
}









