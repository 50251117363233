.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #515151;
  overscroll-behavior: none;
}

.wrapperAudio {
  display: flex;
  width: 100%;
  height: 100%;
  background: linear-gradient(#515151, 90% ,#000000);
}

.gallery {
  display: flex;
  width: 100vw;
  height: 100%;
  margin: 0 auto;
  position: relative;

  .shadow {
    width: 100%;
    height: 34%;
    background-image: linear-gradient(transparent, #000000);
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1000;
  }

  .shortDescriptionWrapper {
    display: flex;
    flex-flow: column;
    position: absolute;
    bottom: 0;
    z-index: 1001;

    .content {
      display: flex;
      flex-flow: column;
      max-width: 60vw;
      width: 100%;
      margin: 0 auto 0 40%;
    }

    .content .title {
      font-size: 28px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      color: #ffffff;
      font-family: "Acumin Pro", sans-serif;
      margin-bottom: 10px;
      max-width: 1300px;
    }

    .content > a {
      display: flex;
      align-items: center;
      cursor: pointer;
      text-decoration: unset;
    }

    .content > a > span {
      font-size: 24px;
      color: #ffffff;
      font-family: "Acumin Pro", sans-serif;
      margin-bottom: 10px;
    }

    .content > a > .arrow {
      background-image: url(../../assets/image/gallery/arrow.png);
      background-position: center;
      height: 18px;
      width: 31px;
      display: block;
      margin-left: 15px;
    }
  }

  .imageWrapper {
    height: 100vh;
    width: 100%;
    display: block;

    .image {
      background-size: auto 100%;
      position: absolute;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      cursor: grab;
      -webkit-transition: .1s, background-image .3s, background-size .3s;
      -o-transition: .1s, background-image .3s, background-size .3s;
      transition: .1s, background-image .3s, background-size .3s;
    }

    .navBtn {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 18px;
      width: 31px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: unset;
      border: none;
      opacity: 1;
      -webkit-transition: .3s ease;
      -o-transition: .3s ease;
      transition: .3s ease;
      z-index: 10000;
      cursor: pointer;
    }

    .navBtn:disabled {
      display: none;
    }

    .right {
      right: 0;
      background-image: url(../../assets/image/gallery/arrow.png);
      transform: rotate(-90deg);
      margin-right: 20px;
    }

    .left {
      background-image: url(../../assets/image/gallery/arrow.png);
      transform: rotate(90deg);
      margin-left: 20px;
    }
  }
}

.galleryMultimedia {
  display: flex;
  width: 100vw;
  height: 100%;
  margin: 0 auto;
  position: relative;

  .multimediaWrapper {
    height: 100%;
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;

    .audioPlayer {
      width: calc(100% - 40px);
      max-width: 760px;
      padding: 0 20px;

      .title {
        font-size: 28px;
        color: #ffffff;
        font-family: "Acumin Pro", sans-serif;
        margin: 100px 0 50px;
      }

      .play {
        width: 60px;
        height: 60px;
        background-image: url(../../assets/image/gallery/big-play.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border: none;
        background-color: transparent;
        transition: 0.3s ease;
        cursor: pointer;

        &.playing {
          background-image: url(../../assets/image/gallery/big-stop.svg);
        }
      }

      .controls {
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        grid-gap: 10px;
        margin-top: 20px;

        .currentTime, .fullTime {
          color: #ffffff;
          font-size: 14px;
          text-align: center;
          font-family: "Acumin Pro", sans-serif;
          margin-top: 5px;
        }

        .progressSection {
          height: 3px;
          background-color: #d0d0d0;
          position: relative;
          margin-top: 3px;

          .progressBar {
            position: absolute;
            background-color: #bf323b;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 0;
            transition: 0.3s;
          }
        }
      }

      .progressWrapper {
        padding: 3px 0;
        position: relative;

        input {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
          z-index: 5;
        }
      }

      .buttonAudio {
        display: flex;
        flex-flow: column;
        width: 100%;
        margin-top: 50px;
      }

      .buttonAudio > a {
        display: flex;
        align-items: center;
        cursor: pointer;
        text-decoration: unset;
      }

      .buttonAudio > a > span {
        font-size: 24px;
        color: #ffffff;
        font-family: "Acumin Pro", sans-serif;
        margin-bottom: 10px;
      }

      .buttonAudio > a > .arrow {
        background-image: url(../../assets/image/gallery/arrow.png);
        background-position: center;
        height: 18px;
        width: 31px;
        display: block;
        margin-left: 15px;
      }

      .descriptionWrapper {
        margin-top: 80px;
      }

      .descriptionInstrument {
        margin-bottom: 80px;
        margin-top: unset;
      }

      .description {
        font-size: 16px;
        color: #ffffff;
        font-family: "Acumin Pro", sans-serif;
        margin-bottom: 20px;

        a {
          color: #ffffff;
          text-decoration: underline;
          text-underline-offset: 2px;
          transition: all 0.3s;
        }
      }
    }

    .videoPlayer {
      width: calc(100% - 40px);
      max-width: 800px;
      padding: 0 20px;
      margin-top: 95px;

      .player {
        width: 100%;
        height: auto;
      }

      .title {
        font-size: 28px;
        color: #ffffff;
        font-family: "Acumin Pro", sans-serif;
        margin: 40px 0 50px;
      }

      .buttonAudio {
        display: flex;
        flex-flow: column;
        width: 100%;
        margin-top: 50px;
      }

      .buttonAudio > a {
        display: flex;
        align-items: center;
        cursor: pointer;
        text-decoration: unset;
      }

      .buttonAudio > a > span {
        font-size: 24px;
        color: #ffffff;
        font-family: "Acumin Pro", sans-serif;
        margin-bottom: 10px;
      }

      .buttonAudio > a > .arrow {
        background-image: url(../../assets/image/gallery/arrow.png);
        background-position: center;
        height: 18px;
        width: 31px;
        display: block;
        margin-left: 15px;
      }

      .descriptionWrapper {
        margin-top: 80px;
      }

      .descriptionInstrument {
        margin-bottom: 80px;
        margin-top: unset;
      }

      .description {
        font-size: 16px;
        color: #ffffff;
        font-family: "Acumin Pro", sans-serif;
        margin-bottom: 20px;

        a {
          color: #ffffff;
          text-decoration: underline;
          text-underline-offset: 2px;
          transition: all 0.3s;
        }
      }
    }
  }
}

  .rightMenu {
    position: absolute;
    right: 55px;
    top: 55px;
    display: flex;
    flex-flow: column;
    z-index: 1001;

    span {
      cursor: pointer;
    }

    .zoom {
      background: url(../../assets/image/gallery/zoom_ico.png) no-repeat center;
      width: 55px;
      height: 56px;
      margin-bottom: 38px;
      cursor: pointer;
    }

    .unZoom {
      background: url(../../assets/image/gallery/unzoom_ico.png) no-repeat center;
      width: 55px;
      height: 56px;
      margin-bottom: 38px;
      cursor: pointer;
    }

    .heart {
      background: url(../../assets/image/gallery/heart_ico.png) no-repeat center;
      width: 53px;
      height: 49px;
      margin-bottom: 38px;
      cursor: pointer;
    }

    .download {
      background: url(../../assets/image/gallery/download_ico.png) no-repeat center;
      width: 49px;
      height: 49px;
      cursor: pointer;
    }

    .disable {
      opacity: 0.5;
      cursor: auto;
    }
  }

  .rightMenuVertical {
    position: absolute;
    right: 30px;
    top: 30px;
    display: flex;
    flex-flow: row;
    z-index: 1001;
  }

  .leftMenu {
    position: fixed;
    left: 50px;
    top: 135px;
    display: flex;
    flex-flow: column;
    z-index: 1001;

    span {
      cursor: pointer;
    }

    .back {
      background: url(../../assets/image/gallery/back_ico.png) no-repeat center;
      width: 62px;
      height: 63px;
    }

    .share {
      display: flex;
      flex-flow: column;
      position: fixed;
      bottom: 20px;
    }
  }

  .downloadContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .downloadWrapper {
      position: absolute;
      right: 60px;
      bottom: 0;
      transition: all .5s ease;
      overflow : hidden;
      background-color: #ffffff;
      flex-flow: column;
      display: none;
      border-radius: 5px;
    }

    .downloadWrapperActive {
      display: flex;
    }

    .downloadButton {
      color: #000000;
      text-decoration: unset;
      flex-shrink: 0;
      white-space: nowrap;
      padding: 10px;
      border-bottom: 1px solid #000000;
      font-family: "Acumin Pro", sans-serif;
    }

    .downloadButton:last-child {
      border-bottom: unset;
    }
  }

.ueLogo {
  position: fixed;
  background: #ffffff url(../../../src/assets/image/header/UE_LOGO.jpg) no-repeat center;
  background-size: 100% auto;
  width: 74px;
  height: 49px;
  z-index: 1000;
  right: 8px;
  bottom: 8px;
}


@media screen and (max-width: 1050px) {
  .galleryMultimedia {

    .multimediaWrapper {

      .audioPlayer{
        max-width: unset;
        margin: 0 130px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .gallery {
    .imageWrapper {

      .right {
        margin-right: 1px;
      }

      .left {
        margin-left: 1px;
      }
    }
  }

  .galleryMultimedia {

    .multimediaWrapper {

      .audioPlayer {
        max-width: unset;
        margin: 0 80px;

        .title {
          font-size: 22px;
          color: #ffffff;
          font-family: "Acumin Pro", sans-serif;
          margin: 80px 0 50px;
        }

        .buttonAudio > a > span {
          font-size: 19px;
        }
      }

      .videoPlayer {

        .title {
          font-size: 24px;
        }

        .descriptionWrapper {
          margin-top: 50px;
        }

        .descriptionInstrument {
          margin-bottom: 50px;
          margin-top: unset;
        }
      }
    }
  }

  .rightMenu {
    right: 30px;
    top: 30px;

      .zoom {
        background-size: cover;
        width: 38px;
        height: 39px;
        margin-bottom: 20px;
      }

      .unZoom {
        background-size: cover;
        width: 38px;
        height: 39px;
        margin-bottom: 20px;
      }

      .download {
        background-size: cover;
        width: 38px;
        height: 38px;
      }
    }

    .rightMenuVertical {
      right: 25px;
      top: 25px;
    }

  .leftMenu {
    left: 30px;
    top: 80px;

    .back {
      width: 38px;
      height: 38px;
      background-size: cover;
    }
  }

  .downloadContainer {

    .downloadWrapper {
      right: 45px;
    }

    .downloadButton {
      padding: 8px;
      font-size: 12px;
    }
  }

  .ueLogo {
    bottom: 4px;
    right: 4px;
    width: 57px;
    height: 37px;
  }
}

@media screen and (max-width: 400px) {
  .gallery {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: hidden;
  }

  .galleryMultimedia{

    .multimediaWrapper {

      .audioPlayer{
        max-width: unset;
        margin: 0 70px;

        .title {
          font-size: 18px;
          margin-bottom: 30px;
        }
      }

      .videoPlayer{

        .title {
          margin-bottom: 30px;
        }
      }
    }
  }

  .rightMenu {
    right: 20px;
    top: 20px;
  }

  .rightMenuVertical {
    right: 15px;
    top: 15px;
  }

  .leftMenu {
    left: 20px;
    top: 70px;
  }
}

@media screen and (min-width: 1920px) {
  .galleryMultimedia {

    .multimediaWrapper {

      .videoPlayer {
        max-width: 41.66vw;
      }
    }
  }
}