.wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: calc(100% - 120px);
  padding: 0 60px;
  margin: 0 auto;
}

.title {
  font-size: 50px;
  padding: 20px 0;
}

.text {
  font-family: "Acumin Pro", sans-serif;

  a {
    color: #000000;
    font-weight: 600;
  }
}


