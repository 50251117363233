.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.left {
  display: flex;
  max-width: 650px;
  width: 100%;
  height: 100vh;
  background-color: #515151;

  .leftMenu {
    position: fixed;
    margin-left: 50px;
    margin-top: 135px;
    display: flex;
    flex-flow: column;
    z-index: 1001;

    span {
      cursor: pointer;
    }

    .back {
      width: 62px;
      height: 63px;
      background: #515151 url(../../assets/image/gallery/back_ico.png) no-repeat center;
      border-radius: 50%;
    }
  }

  .imagesWrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 70px 70px 0 70px;
    margin-left: 115px;
    overflow-y: auto;
  }

  .image {
    width: 100%;
    height: auto;
    padding-bottom: 70px;
  }

  .imageMultimedia {
    width: 100%;
    height: auto;
  }
}


.leftSmall {
  max-width: 162px;
}

.rightWrapper {
  width: 100%;
  display: flex;
  height: 100vh;
}

.right {
  padding: 75px 0 75px 75px;
  width: 100%;
  display: flex;
  flex-flow: column;
  overflow-y: auto;

  a {
    color: #000000;
  }

  .mainTitle {
    font-size: 24px;
    line-height: 24px;
    color: #c08164;
    font-family: "Acumin Pro", serif;
    padding-right: 50px;
  }

  .title {
    font-size: 40px;
    line-height: 51px;
    color: #010000;
    font-weight: bold;
    font-family: "Acumin Pro", sans-serif;
    margin: 20px 0;
    padding-right: 50px;
  }

  .blockTextWrapper {
    padding-right: 50px;
    display: flex;
    flex-flow: column;

    .blockTitle {
      font-size: 24px;
      color: #000000;
      font-weight: bold;
      font-family: "Acumin Pro", sans-serif;
      margin: 20px 0;
    }

    .text {
      font-size: 24px;
      letter-spacing: 0;
      color: #000000;
      font-family: "Acumin Pro", sans-serif;

      a {
        text-decoration: underline;
        text-underline-offset: 3px;
      }
    }

    .box2 {
      display: flex;
      flex-wrap: wrap;
    }

    .box {
      display: flex;
      margin: 5px 10px;
      flex-wrap: wrap;

      .boxTitle {
        font-size: 20px;
        color: #000000;
        font-weight: bold;
        font-family: "Acumin Pro", sans-serif;
        white-space: nowrap;
      }

      .boxText {
        font-size: 20px;
        letter-spacing: 0;
        color: #000000;
        font-family: "Acumin Pro", sans-serif;
        margin-left: 5px;

        a {
          text-decoration: underline;
          text-underline-offset: 3px;
        }
      }
    }
  }
}

.ueLogo {
  position: fixed;
  background: #ffffff url(../../../src/assets/image/header/UE_LOGO.jpg) no-repeat center;
  background-size: 100% auto;
  width: 74px;
  height: 49px;
  z-index: 1000;
  right: 15px;
  bottom: 10px;
}

@media screen and (max-width: 800px) {
  .left {

    .leftMenu {
      margin-left: 30px;
      margin-top: 80px;

      .back {
        width: 38px;
        height: 39px;
        background-size: cover;
      }
    }

    .imagesWrapper {
      padding: 30px 30px 0 30px;
      margin-left: 70px;

      .image {
        padding-bottom: 30px;
      }
    }
  }

  .right {
    padding: 35px 0 35px 35px;

    .title {
      font-size: 28px;
      line-height: 30px;
      margin: 20px 0;
      padding-right: 20px;
    }

    .blockTextWrapper {
      padding-right: 20px;

      .blockTitle {
        font-size: 19px;
        margin: 16px 0;
      }

      .text {
        font-size: 19px;
      }

      .box {

        .boxTitle {
          font-size: 16px;
        }

        .boxText {
          font-size: 16px;
        }
      }
    }
  }

  .ueLogo {
    bottom: 4px;
    right: 4px;
    width: 57px;
    height: 37px;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    flex-flow: column;
  }

  .left {
    max-width: unset;
    height: unset;
  }

  .rightWrapper {
    height: unset;
  }

  .right {
    width: calc(100% - 70px);
  }
}

@media screen and (max-width: 400px) {
  .left {

    .leftMenu {
      margin-left: 20px;
      margin-top: 70px;

      .back {
        width: 38px;
        height: 39px;
      }
    }
  }

  .right {
    padding: 25px;

    .mainTitle {
      padding-right: 0;
    }

    .title {
      padding-right: 0;
    }

    .blockTextWrapper {
      padding-right: 0;
    }
  }
}

@media screen and (min-width: 2560px) {
  .left {
    width: 33%;
    max-width: unset;
    flex-shrink: 0;
  }

  .rightWrapper {
    width: calc(100% - 25.39%);
  }
}