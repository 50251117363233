.wrapper {
  width: 100%;
  display: flex;
  flex-flow: row;
  position: relative;
  align-items: center;
  font-family: "Acumin Pro", sans-serif;
  transition: 0.2s opacity;
  padding-bottom: 43px;

  .inputWrapper {
    display: flex;
    width: 100%;
    position: relative;

    .searchInput {
      font-size: 22px;
      letter-spacing: 0;
      line-height: 60px;
      font-family: "Acumin Pro", sans-serif;
      outline: unset;
      width: 100%;
      border: unset;
      transition: all 0.3s;
      background-color: unset;
      border-bottom: 2px solid #ffffff;
      color: #ffffff;
    }

    .saveWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: 0.3s all;
      background-color: #bf323b;
      margin-left: 20px;
      width: 90px;
      flex-shrink: 0;
      border: 3px solid #ffffff;
      color: #ffffff;

      .save {
        font-size: 22px;
        padding: 0 10px;
        font-family: "Acumin Pro", sans-serif;
        margin-top: 3px;
      }
    }

    .saveWrapperSaved {
      background-color: unset;
    }

    .searchInputOpen::placeholder {
      color: #ffffff !important;
      opacity: 1;
    }
  }
}