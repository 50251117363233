@import '../../assets/styles/index';

.wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 2560px;
}

.searchWrapper {
  display: flex;
  background-color: #bf323b;
  height: 325px;
  width: 100%;
  border-radius: 50% / 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -600px;
  padding-top: 600px;
  flex-flow: column;
  align-items: center;
  transition: all 0.3s;
  position: relative;

  .inputWrapper {
    position: relative;

    .searchInput {
      font-size: 24px;
      letter-spacing: 0;
      line-height: 91px;
      color: #000000;
      font-family: "Acumin Pro", sans-serif;
      outline: unset;
      width: 936px;
      height: 76px;
      background-color: #ffffff;
      border: unset;
      margin-top: 50px;
      padding: 0 90px 0 26px;
      transition: all 0.3s;
    }

    .searchInputOpen {
      background-color: unset;
      border-bottom: 2px solid #ffffff;
      padding: 0 90px 0 0;
      color: #ffffff;
      transition: all 0.3s;
    }

    .searchInputOpen::placeholder {
      color: #ffffff !important;
      opacity: 1;
    }

    .searchInput::placeholder {
      color: #000000;
      opacity: 1;
    }

    .searchButton {
      background: url(../../../src/assets/image/list/search-icon.svg) no-repeat center;
      position: absolute;
      height: 45px;
      width: 48px;
      top: 65px;
      right: 20px;
      cursor: pointer;
    }

    .searchButtonOpen {
      background: url(../../../src/assets/image/list/search-icon-white.png) no-repeat center;
      background-size: 45px 45px;
    }
  }

  .inputsWrapper {
    display: none;
    width: 100%;
  }

  .inputsWrapperOpen {
    display: flex;
  }

  .advSearch {
    font-size: 26px;
    color: #ffffff;
    font-family: "Acumin Pro", sans-serif;
    text-align: center;
    position: absolute;
    bottom: 110px;
  }

  .advSearchIco {
    background: url(../../../src/assets/image/list/arrow.png) no-repeat center;
    width: 45px;
    height: 19px;
    cursor: pointer;
    position: absolute;
    bottom: 60px;
  }
}

.searchWrapperOpen {
  display: flex;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 110px;
  height: 600px;
  padding-bottom: 70px;
  padding-top: 650px;
  background-color: #515151;
}

.advancedSearchButton {
  display: flex;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 110px;
  height: auto;
  padding-bottom: 10px;
  padding-top: 650px;
  background-color: #515151;
}

@media screen and (max-width: 1800px) {
  .searchWrapper {
    height: 260px;
    margin-top: -438px;
    padding-top: 438px;

    .inputWrapper{

      .searchInput {
        width: 748px;
        margin-top: 40px;
      }

      .searchButton {
        top: 56px;
      }
    }

    .advSearch {
      bottom: 80px;
    }

    .advSearchIco {
      bottom: 35px;
    }
  }

  .searchWrapperOpen {
    display: flex;
    padding-top: 480px;
    height: 600px;
    padding-bottom: 70px;
  }

  .advancedSearchButton {
    height: auto;
  }
}

@media screen and (max-width: 1500px) {
  .searchWrapper {
    height: 208px;
    max-width: 1088px;
    margin-top: -238px;
    padding-top: 238px;

    .inputWrapper{

      .searchInput {
        width: 520px;
        margin-top: 32px;
        height: 60px;
        padding: 0 70px 0 26px;
      }

      .searchButton {
        top: 45px;
        height: 36px;
        width: 37px;
        right: 15px;
        background-size: cover;
      }
    }

    .advSearch {
      bottom: 60px;
    }

    .advSearchIco {
      bottom: 30px;
    }
  }

  .searchWrapperOpen {
    display: flex;
    padding-top: 280px;
    height: 550px;
    padding-bottom: 70px;
  }

  .advancedSearchButton {
    height: auto;
  }
}

@media screen and (max-width: 1100px) {
  .searchWrapper {
    height: 166px;
    margin-top: -317px;
    padding-top: 317px;

    .inputWrapper{

      .searchInput {
        margin-top: 20px;
        width: 416px;
      }

      .searchButton {
        top: 34px;
      }
    }

    .advSearch {
      font-size: 18px;
      bottom: 50px;
    }

    .advSearchIco {
      background-size: cover;
      width: 31px;
      height: 13px;
      bottom: 25px;
    }
  }

  .searchWrapperOpen {
    display: flex;
    padding-top: 367px;
    height: 450px;
    padding-bottom: 70px;
  }

  .advancedSearchButton {
    height: auto;
  }
}

@media screen and (max-width: 1000px) {
  .searchWrapper {
    height: 132px;
    margin-top: -253px;
    padding-top: 253px;

    .inputWrapper{

      .searchInput {
        margin-top: 16px;
        width: 330px;
        height: 48px;
        padding: 0 52px 0 20px;
      }

      .searchButton {
        top: 26px;
        height: 31px;
        width: 31px;
        right: 10px;
      }
    }

    .advSearch {
      bottom: 35px;
    }

    .advSearchIco {
      bottom: 15px;
    }
  }

  .searchWrapperOpen {
    display: flex;
    padding-top: 293px;
    height: 420px;
    padding-bottom: 70px;
  }

  .advancedSearchButton {
    height: auto;
  }
}

@media screen and (max-width: 740px) {
  .searchWrapper {
    .advSearch {
      bottom: 35px;
    }

    .advSearchIco {
      bottom: 15px;
    }
  }

  .searchWrapperOpen {
    display: flex;
    height: 530px;
    padding-bottom: 70px;
  }

  .advancedSearchButton {
    height: auto;
  }
}

@media screen and (max-width: 800px) {
  .searchWrapper {

    .inputWrapper {

      .searchInput {
        width: 285px;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .searchWrapper {

    .inputWrapper {

      .searchInput {
        width: 265px;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .searchWrapper {

    .inputWrapper {

      .searchInput {
        width: 245px;
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .searchWrapper {
    border-radius: unset;
    height: 130px;

    .inputWrapper{

      .searchInput {
        width: 380px;
        font-size: 19px;
      }
    }

    .advSearch {
      bottom: 35px;
    }

    .advSearchIco {
      bottom: 15px;
    }
  }

  .searchWrapperOpen {
    display: flex;
    height: 530px;
    padding-bottom: 70px;
  }

  .advancedSearchButton {
    height: auto;
  }
}

@media screen and (max-width: 500px) {
  .searchWrapper {

    .inputWrapper{

      .searchInput {
        width: 320px;
        font-size: 19px;
      }
    }
  }
}

@media screen and (max-width: 470px) {
  .searchWrapperOpen {
    display: flex;
    height: 530px;
    padding-bottom: 10px;
    margin-bottom: 0;
  }

  .advancedSearchButton {
    height: auto;
  }
}

@media screen and (max-width: 400px) {
  .searchWrapper {

    .inputWrapper{

      .searchInput {
        width: 220px;
        font-size: 19px;
      }
    }
  }
}