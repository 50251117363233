.loader{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 10000;
    background-color: white;
    flex-flow: column;

    .text {
        margin-top: 30px;
        font-size: 20px;
        letter-spacing: 0;
        color: #000000;
        font-family: "Acumin Pro", sans-serif;
        padding: 0 15px;
        text-align: center;
    }
}

.ldsDualRing {
    display: inline-block;
    width: 80px;
    height: 80px;
}
.ldsDualRing:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid;
    border-color: #515151 transparent #515151 transparent;
    animation: ldsDualRing 1.2s linear infinite;
}
@keyframes ldsDualRing {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}