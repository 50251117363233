.cookieContainer {
    background-color: #ededed;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
    border-radius: 10px;
    font-family: 'Acumin Pro';
    position: fixed;
    bottom: -50px;
    right: 50%;
    transform: translateX(50%);
    opacity: 0;
    z-index: 1000;
    transition: opacity 0.5s ease, bottom 0.5s ease;
}

.cookieContainer.visible {
    bottom: 50px;
    opacity: 1;
}

.cookieContainer.hidden {
    opacity: 0;
    bottom: -50px;
    pointer-events: none; 
}
.cookieContainer .text{
    display: none;
    margin: 0 20px;
}

.cookieContainer .text strong{
    font-weight: bold;     
}

.cookieContainer .cookieBtnWhite, .cookieBtnRed {
    display: none;
}

.cookieContainer .text, .cookieContainer .text a {
    font-size: 15px;
    font-weight: 100;
    color: black;
    line-height: 20px;
    position: relative;
}

.cookieContainer .text a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 0%;
    height: 2px;
    background-color: #B22222;;
    transition: width 0.3s ease-in-out;
}

.cookieContainer .text a:hover::after {
    width: 100%;
}


.cookieContainer .closeBtn {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    margin: 5px 5px 0 0;
}

.cookieContainer .closeBtn svg {
    transition: 0.3s ease;
  }

.cookieContainer .closeBtn svg:hover {
  transform: scale(1.1);
}

.cookieContainer .btnContainer {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
}

.cookieContainer .btnContainer .cookieBtnWhite, .cookieContainer .btnContainer .cookieBtnRed {
    padding: 8px 15px;
    border-radius: 20px;
    font-size: 17px;
    font-weight: 300;
    background: none;
    border: none;
    max-width: 180px;
    width: 100%;
    transition: all 0.3s ease-in-out;
}

.cookieContainer .btnContainer .cookieBtnWhite:hover, .cookieContainer .btnContainer .cookieBtnRed:hover {
    cursor: pointer;
}

.cookieContainer .btnContainer .cookieBtnWhite {
    color: #B22222;
    border: 1px solid #B22222;
    background-color: white;
}

.cookieContainer .btnContainer .cookieBtnWhite:hover {
    background-color: #B22222;
    color: white;
    border-color: white;
}

.cookieContainer .btnContainer .cookieBtnRed {
    color: white;
    background-color: #B22222;
}

.cookieContainer .btnContainer .cookieBtnRed:hover {
    background-color: white;
    color: #B22222;
    border: 1px solid #B22222;
}

@media screen and (max-width: 650px) {
    .cookieContainer {
        width: 400px;
    }
    .cookieContainer .btnContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px
    }

    .cookieContainer .btnContainer .cookieBtnRed, .cookieContainer .btnContainer .cookieBtnWhite {
        width: 100%;
        max-width: none;
    }
}

@media screen and (max-width: 450px) {
    .cookieContainer {
        width: 90%
    }
   
}
