body {
  display: flex;
  flex-flow: column;
  margin: 0 auto;
  -webkit-tap-highlight-color: transparent;
}

* {
  outline: 0;
}

*:hover {
  outline: 0;
}

*:focus {
  outline: 0;
}

a {
  outline: 0;
  text-decoration: unset;
}

a:hover, a:active, a:focus {
  outline: 0;
}

input {
  outline: 0;
}

input:hover, input:active, input:focus {
  outline: 0;
}

button {
  outline: 0;
}

button:hover, button:active, button:focus {
  outline: 0;
}

@font-face {
  font-family: 'Acumin Pro';
  src: url('../fonts/Acumin/AcuminPro-BoldItalic.eot');
  src: url('../fonts/Acumin/AcuminPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Acumin/AcuminPro-BoldItalic.woff2') format('woff2'),
  url('../fonts/Acumin/AcuminPro-BoldItalic.woff') format('woff'),
  url('../fonts/Acumin/AcuminPro-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Acumin Pro';
  src: url('../fonts/Acumin/AcuminPro-Bold.eot');
  src: url('../fonts/Acumin/AcuminPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Acumin/AcuminPro-Bold.woff2') format('woff2'),
  url('../fonts/Acumin/AcuminPro-Bold.woff') format('woff'),
  url('../fonts/Acumin/AcuminPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Acumin Pro';
  src: url('../fonts/Acumin/AcuminPro-Italic.eot');
  src: url('../fonts/Acumin/AcuminPro-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Acumin/AcuminPro-Italic.woff2') format('woff2'),
  url('../fonts/Acumin/AcuminPro-Italic.woff') format('woff'),
  url('../fonts/Acumin/AcuminPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Acumin Pro';
  src: url('../fonts/Acumin/AcuminPro-Regular.eot');
  src: url('../fonts/Acumin/AcuminPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Acumin/AcuminPro-Regular.woff2') format('woff2'),
  url('../fonts/Acumin/AcuminPro-Regular.woff') format('woff'),
  url('../fonts/Acumin/AcuminPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Anglecia';
  src: url('../fonts/Angelica/AngleciaProDisplay-Regular.eot');
  src: url('../fonts/Angelica/AngleciaProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Angelica/AngleciaProDisplay-Regular.woff2') format('woff2'),
  url('../fonts/Angelica/AngleciaProDisplay-Regular.woff') format('woff'),
  url('../fonts/Angelica/AngleciaProDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Book';
  src: url('../fonts/Avenir/Avenir-Book.eot');
  src: url('../fonts/Avenir/Avenir-Book.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Avenir/Avenir-Book.woff2') format('woff2'),
  url('../fonts/Avenir/Avenir-Book.woff') format('woff'),
  url('../fonts/Avenir/Avenir-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Book';
  src: url('../fonts/Avenir/Avenir-BookOblique.eot');
  src: url('../fonts/Avenir/Avenir-BookOblique.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Avenir/Avenir-BookOblique.woff2') format('woff2'),
  url('../fonts/Avenir/Avenir-BookOblique.woff') format('woff'),
  url('../fonts/Avenir/Avenir-BookOblique.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}