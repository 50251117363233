.wrapper {
  width: 100%;
  display: flex;
  flex-flow: column;
  margin: 0 auto;
}

.oval {
  display: flex;
  height: 171px;
  background: url(../../../src/assets/image/footer/background.jpg) no-repeat center;
  flex-flow: row;
  justify-content: center;

  .socialWrapper {
    display: flex;
    flex-flow: row;
    max-width: 565px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
  }

  .fb,.yt {
    margin-top: 30px;
  }

  .tw,.ig {
    margin-bottom: 30px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.greyWrapper {
  width: 100%;
  background-color: #ededed;

  .content {
    display: flex;
    width: 100%;
    max-width: 890px;
    padding: 23px 0;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;

    .copyright {
      font-size: 24px;
      color: #aaaaaa;
      font-weight: bold;
      font-family: "Acumin Pro", sans-serif;
    }
  }
}

.imagesWrapper {
  width: 100%;
  max-width: 980px;
  display: flex;
  padding: 35px 0;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1200px) {
  .oval {
    background-size: auto 136px;
    height: 136px;

    .socialWrapper {
      max-width: 452px;

      img {
        height: 41px;
      }
    }
  }

  .greyWrapper {

    .content {
      max-width: 712px;

      img {
        height: 49px;
      }

      .copyright {
        font-size: 19px;
      }
    }
  }

  .imagesWrapper {
    max-width: 784px;
    padding: 35px 10px;

    img {
      height: 48px;
    }
  }
}

@media screen and (max-width: 900px) {
  .oval {
    background-size: auto 108px;
    height: 108px;
  }

  .greyWrapper {

    .content {
      max-width: 569px;
      flex-wrap: wrap;
      justify-content: center;

      img {
        margin: 0 auto;
      }

      .copyright {
        padding-top: 23px;
      }
    }
  }

  .imagesWrapper {
    max-width: 700px;
  }
}

@media screen and (max-width: 730px) {
  .oval {
    background-size: auto 86px;
    height: 86px;

    .socialWrapper {
      max-width: 361px;

      .fb,.yt {
        margin-top: 10px;
      }

      .tw,.ig {
        margin-bottom: 30px;
      }
    }
  }

  .greyWrapper {

    .content {

      img {
        height: 61px;
      }
    }
  }

  .imagesWrapper {
    display: grid;
    grid-template-columns: 0.8fr 1.2fr;
    width: calc(100% - 20px);
    grid-row-gap: 20px;

    img {
      height: 60px;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 580px) {
  .oval {
    background-size: auto 200px;
    height: 86px;

    .socialWrapper {
      max-width: unset;
      padding-top: 0;
      justify-content: space-evenly;

      .fb,.yt {
        margin-top: 0;
      }

      .tw,.ig {
        margin-bottom: 0;
      }
    }
  }

  .greyWrapper {

    .content {

      .copyright {
        padding: 23px 10px 0 10px;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .imagesWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
}